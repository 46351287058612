import axios from "@/axios";
export default {
  state: {
    feedbacks: {},
  },
  mutations: {
    SET_Feedback(state, data) {
      state.feedbacks = data;
    },
  },
  actions: {
    Add_Feedback({ commit }, payload) {
      axios
        .post("api/user/feedback", payload)
        .then((response) => {
          const feedback = response.data.data;
          commit("SET_Feedback", feedback);
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
