<template>
  <div id="loginPage" class="bg-white">
    <div class="login-page-wrapper bg-white">
      <div class="text-center">
        <img
          src="../assets/interceptionLogo.png"
          alt=" loading"
          class="img-fluid login-page-logo"
        />
        <h2 class="mt-4">Log in to your account</h2>
      </div>
      <div class="login-btn-Wrapper">
        <b-form @submit="onSubmit" v-if="show">
          <b-form-group
            id="example-input-group-1"
            label="Email"
            label-for="example-input-1"
            class="mt-3"
          >
            <b-form-input
              id="example-input-1"
              UserEmail="example-input-1"
              placeholder="Email Address"
              v-model="$v.form.UserEmail.$model"
              class="input-fields"
              type="email"
              ref="input1"
              :state="validateStateUserEmail('UserEmail')"
              aria-describedby="input-6-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-6.1-live-feedback"
              >This is a required field and must be valid and follows email
              pattern example@gmail.com</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group
            id="example-input-group-2"
            label="Password"
            label-for="example-input-2"
            class="mt-3"
          >
            <div class="position-relative">
              <b-form-input
                id="example-input-2"
                confirmPassword="example-input-2"
                placeholder="Password"
                ref="input2"
                class="input-fields password_fields"
                v-model="$v.form.Password.$model"
                :state="validateStatePassword('Password')"
                aria-describedby="input-5-live-feedback"
                :type="showPassword ? 'text' : 'password'"
              >
              </b-form-input>
              <span
                class="SignIn-btn-eye-close"
                ref="eyeBtn"
                @click="toggleShowPassword"
              >
                <b-icon
                  class="text-danger"
                  v-if="showPassword"
                  icon="eye-fill"
                ></b-icon>
                <b-icon
                  class="text-black"
                  v-else
                  icon="eye-slash-fill"
                ></b-icon>
              </span>
            </div>
            <b-form-invalid-feedback id="input-5.1-live-feedback">
              This is a required field and must be
              valid</b-form-invalid-feedback
            >
          </b-form-group>

          <!-- <div class="mt-4 btn-wrappers">
            <b-button class="d-flex bg-white btn-microsoft">
              <img
                src="../assets/google.svg"
                class="googleLogo"
                alt="loading"
              />
              <span class="text-dark">Sign in with Google</span>
            </b-button>
          </div>
          <div class="mt-2 btn-wrappers">
            <b-button class="d-flex bg-white btn-microsoft">
              <img src="../assets/microsoft.png" alt="loading" />
              <span class="text-dark">Sign in with Microsoft</span>
            </b-button>
          </div> -->
          <button type="submit" class="submition-button input-fields">
            Sign In
          </button>
        </b-form>
      </div>
      <div class="link-signup text-center mt-3">
        <span>Don't have an account?</span>
        <router-link to="/signup"> Sign up </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import axios from "@/axios";
import { required } from "vuelidate/lib/validators";
const emailPattern = (value) => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return pattern.test(value);
};
export default {
  name: "LoginPage",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        Password: null,
        UserEmail: null,
      },
      show: true,
      showPassword: false,
      toastErrorMsg: "",
      toastSuccessMsg: "Login successful validation confirmed",
      toast: false,
      toastSuccess: false,
    };
  },
  validations: {
    form: {
      UserEmail: {
        required,
        emailPattern,
      },
      Password: {
        required,
      },
    },
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const formData = {
        email: this.form.UserEmail,
        password: this.form.Password,
      };
      await axios
        .post("api/login", formData)
        .then((response) => {
          this.$bvToast.toast("Login successful validation confirmed", {
            title: "Login successful",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "success",
          });
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userId", response.data.data.id);
          this.$store.commit("user/SET_USER", response.data.data);
          setTimeout(() => {
            this.$router.push("/");
          }, 2000);
          this.Reset();
        })
        .catch((error) => {
          this.$bvToast.toast(`Access denied : ${error}`, {
            title: "Operation unsuccessful",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "danger",
          });
          console.error(error);
        });
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    Reset() {
      this.form.UserEmail = "";
      this.form.Password = "";
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    validateStateUserEmail(validateStateUserEmail) {
      const { $dirty, $error } = this.$v.form[validateStateUserEmail];
      return $dirty ? !$error : null;
    },
    validateStatePassword(validateStatePassword) {
      const { $dirty, $error } = this.$v.form[validateStatePassword];
      return $dirty ? !$error : null;
    },
  },
};
</script>
