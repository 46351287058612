import Vue from "vue";
import Vuex from "vuex";
import user from "./user";
import threads from "./threads/threads";
import conversation from "./conversation/conversation";
import feedback from "./feedback/feedback";
Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user: user,
    threads,
    conversation,
    feedback,
  },
});
