<template>
  <div class="outer-container container-fluid m-0 p-0">
    <GlobalLoader v-if="globalLoader" />
    <div class="home-header-wrapper d-flex">
      <HomeSideBar />
      <div class="wrapper d-flex" ref="wrapper">
        <div v-if="showThreads" class="threads-controllers">
          <ThreadsComponent :isOpenTreads="showThreads" />
        </div>
        <div class="inputFieldWrapper gap-2">
          <h4>Ask Gema, your AI Research Companion</h4>
          <form @submit.prevent="query()" class="my-6">
            <div class="d-flex inputField d-flex mb-4">
              <input
                placeholder="Write something"
                class="inputText"
                v-model="askAi"
              />
              <button class="btn-send p-2 b-0">
                <img src="../assets/send.svg" alt="loading" />
              </button>
            </div>
          </form>
          <p>Examples of what Gema can help you with</p>
          <ul class="recentChats">
            <li
              class="item-chat"
              v-for="(chat, index) in recentChats"
              :key="index"
            >
              <button
                class="btn-chats text-black"
                @click="SearchDefault(chat.title)"
              >
                <div class="textWrapper">
                  {{ chat.title }}
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ThreadsComponent from "../components/Threads.vue";
import GlobalLoader from "../components/GlobalLoader.vue";
import HomeSideBar from "../components/HomeSideBar.vue";
export default {
  name: "HomeDashboard",
  components: {
    HomeSideBar,
    ThreadsComponent,
    GlobalLoader,
  },
  data() {
    return {
      sideBarControl: false,
      threads: false,
      askAi: "",
      token: localStorage.getItem("token"),
      threadsList: "",
      chat_id: "",
      queryDefault: "",
      recentChats: [
        {
          title:
            " Find the most effective ways to exract soluble protein from leafy crops",
          id: "thread1",
        },
        {
          title: "what are the importance of biology in life  ",
          id: "thread2",
        },
      ],
    };
  },
  methods: {
    showSideBar() {
      this.sideBarControl = !this.sideBarControl;
    },
    async GET_THREADS_DATA() {
      await this.$store.dispatch("threads/GET_THREADS");
    },
    SearchDefault(text) {
      this.askAi = text;
      this.query();
    },
    showChatsBox() {
      this.router.push("/");
    },
    query() {
      this.$store.commit("GLOBAL_LOADER", true);
      const formData = {
        title: this.askAi,
      };

      this.$store
        .dispatch("conversation/GET_CONVERSATION", formData)
        .then((response) => {
          this.$store.commit("conversation/GLOBAL_LOADER", false);
          response.data.data["type"] = "user";
          response.data.data["message"] = response.data.data.title;
          this.$router.push("/chat/" + response.data.data.id);

          this.$store.commit(
            "conversation/SET_CHAT_CONVERSATION",
            response.data.data
          );
          this.$store.commit("conversation/SET_LOADER", true);

          const chatData = {
            chat_id: response.data.data.id,
            message: response.data.data.title,
          };

          this.$store
            .dispatch("conversation/POST_MESSAGE_TO_AI", chatData)
            .then((aiResponse) => {
              this.$store.commit(
                "conversation/SET_CHAT_CONVERSATION",
                aiResponse.data.data
              );
              this.$store.commit("conversation/SET_LOADER", false);
            })
            .catch((error) => {
              this.$store.commit("conversation/SET_LOADER", false);
              console.error(error);
            });
        })
        .catch((error) => {
          this.$store.commit("conversation/GLOBAL_LOADER", false);
          console.error(error);
        });
    },
    // query() {
    //   // this.$store.commit("conversation/GLOBAL_LOADER", true);
    //   this.$store.commit("conversation/SET_LOADER", true);
    //   const formData = {
    //     title: this.askAi,
    //   };
    //   this.$store
    //     .dispatch("GET_CONVERSATION", formData)
    //     .then((response) => {
    //       response.data.data["type"] = "user";
    //       response.data.data["message"] = response.data.data.title;
    //       this.$router.push("/chat/" + response.data.data.id);
    //       this.$store.commit("conversation/SET_CHAT_CONVERSATION", response.data.data);
    //       this.$store.commit("conversation/SET_LOADER", true);

    //       const chatData = {
    //         chat_id: response.data.data.id,
    //         message: response.data.data.title,
    //       };
    //       this.chat_id = response.data.data.id;
    //       this.$store
    //         .dispatch("conversation/POST_MESSAGE_TO_AI", chatData)
    //         .then((aiResponse) => {
    //           // this.$store.commit("conversation/GLOBAL_LOADER", false);
    //           // this.$router.push("/chat/" + aiResponse.data.data.chat_id);
    //           this.$store.commit("conversation/SET_CHAT_CONVERSATION", aiResponse.data.data);
    //           this.$store.commit("conversation/SET_LOADER", false);
    //         })
    //         .catch((error) => {
    //           // this.$store.commit("conversation/GLOBAL_LOADER", false);
    //           this.$store.commit("conversation/SET_LOADER", false);
    //           console.error(error);
    //         });
    //     })
    //     .catch((error) => {
    //       // this.$store.commit("conversation/GLOBAL_LOADER", false);
    //       this.$store.commit("conversation/SET_LOADER", false);
    //       console.error(error);
    //     });
    // },
  },
  async mounted() {
    await this.GET_THREADS_DATA();
  },
  computed: {
    globalLoader() {
      return this.$store.getters["conversation/GlobalLoader"];
    },
    showThreads() {
      return this.$store.getters["threads/show_Threads"];
    },
  },
};
</script>
