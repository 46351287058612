<template>
  <div class="outer-container container-fluid m-0 p-0">
    <div class="chat-page-outer-wrapper d-flex">
      <HomeSideBar />
      <div class="chat-page-wrapper d-flex" ref="wrapper">
        <div v-if="showThreads" class="threads-controllers">
          <ThreadsComponent :isOpenTreads="showThreads" />
        </div>
        <div class="chatbox-controller">
          <ChatBox :chatId="+$route.params.id" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ThreadsComponent from "../components/Threads.vue";
import ChatBox from "../components/ChatBox.vue";
import HomeSideBar from "../components/HomeSideBar.vue";

export default {
  name: "ChatsPage",
  components: {
    ThreadsComponent,
    ChatBox,
    HomeSideBar,
  },
  data() {
    return {
      sideBarControl: false,
      askAi: "",
      token: localStorage.getItem("token"),
    };
  },
  methods: {
    showSideBar() {
      this.sideBarControl = !this.sideBarControl;
    },
    GET_THREADS_DATA() {
      this.$store.dispatch("threads/GET_THREADS");
    },
    showChatsBox() {
      this.$router.push("/");
    },
    getconversations() {
      const chatid = this.$route.params.id;
      this.$store
        .dispatch("conversation/get_user_conversations", chatid)
        .then((response) => {
          this.$store.commit(
            "conversation/set_user_conversation",
            response.data.data.data
          );
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  computed: {
    showThreads() {
      return this.$store.getters["threads/show_Threads"];
    },
  },
  mounted() {
    this.$store.commit("threads/SHOW_THREADS_COMPONENT", false);
    this.GET_THREADS_DATA();
    if (this.$store.getters["conversation/conversations"].length !== 1) {
      this.getconversations();
    }
  },
};
</script>
