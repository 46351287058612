<template>
  <div class="Threadswrapper p-2" v-if="isOpenTreads">
    <ul>
      <li class="thread" v-for="(thread, index) in threads" :key="index">
        <router-link :to="`/chat/${thread.id}`">
          <div class="chat-icon">
            <img src="../assets/chat.svg" class="mr-3" alt="loading" />
          </div>
          <div class="chat-title">
            {{ thread.title }}
          </div>
          <div class="chat-count" v-if="thread.count">{{ thread.count }}</div>
        </router-link>
      </li>
    </ul>
    <infinite-loading @infinite="infiniteHandler"></infinite-loading>
  </div>
</template>
<script>
export default {
  name: "ThreadsComponent",
  props: {
    isOpenTreads: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      page: 1,
    };
  },
  methods: {
    infiniteHandler($state) {
      this.$store
        .dispatch("threads/GET_THREADS", this.page)
        .then((response) => {
          const threadsList = response.data.data.data;
          threadsList.forEach((element) => {
            this.$store.commit("threads/SET_THREADS", element);
          });
          if (this.page == response.data.data.last_page) {
            $state.complete();
          } else {
            $state.loaded();
            this.page += 1;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  computed: {
    threads() {
      return this.$store.getters["threads/threads"];
    },
  },
  destroyed() {
    this.$store.commit("threads/STATE_REFRESH");
  },
};
</script>
