<template>
  <div class="chat-Box-wrapper" id="chatBox">
    <ToastMessage v-if="copiedToast" />
    <ToastMessageFlag v-if="flagTost" />

    <div class="Chat-Box-header p-3">
      <div>
        <div class="user">
          <span>G</span>
        </div>
      </div>
      <div class="userInfo">
        <div class="queryHeader">
          {{
            conversations && conversations.length > 0
              ? conversations[0].message
              : "chat title"
          }}
        </div>
        <div class="text-muted">
          {{
            conversations && conversations.length > 0
              ? moment(conversations[0].created_at).format("LLLL")
              : time
          }}
        </div>
      </div>
    </div>
    <div class="chatList" ref="scrollContainer">
      <ul>
        <li class="mt-2" v-for="(message, index) in conversations" :key="index">
          <div :class="message.type === 'user' ? 'send' : 'recieve'">
            <div>
              <div class="user" v-if="message.type !== 'user'">
                <span>G</span>
              </div>
            </div>

            <div class="innerWrapper">
              <div class="chatsDetails d-flex">
                <span class="users-name">
                  {{ message.type === "user" ? "You" : "Gema" }}
                </span>
                <span class="date-and-time small text-gray">{{
                  message.created_at && message.created_at.length > 0
                    ? moment(message.created_at).format("LLLL")
                    : "chat title"
                }}</span>
              </div>
              <div
                ref="output"
                :class="
                  message.type === 'user'
                    ? 'message text-black'
                    : 'message text-black Ai_Response'
                "
              >
                {{ message.message }}
                <hr class="text-gray" v-if="message.type !== 'user'" />
                <div
                  class="d-flex align-items-center justify-content-between chat-btn-controller-wrapper"
                  v-if="message.type !== 'user'"
                >
                  <button
                    class="bg-light chat-box-btn-copy copyText"
                    @click="copyTextControl(message.message)"
                  >
                    <img src="../assets/copylogo.svg" alt="loading" />
                    <span class="text-black">Copy</span>
                  </button>
                  <div class="message-control-buttons-wrapper">
                    <button
                      class="bg-light chat-box-btn-flag"
                      @click="flagTextControl()"
                    >
                      <img src="../assets/flag.svg" alt="loading" />
                    </button>
                    <AddComment :messageId="message.id" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <AppLoader v-if="load" />
      </ul>
    </div>
    <div class="chatBox">
      <ChatInput />
    </div>
  </div>
</template>
<script>
import ChatInput from "../components/ChatInput.vue";
import ToastMessage from "../components/ToastMessage.vue";
import ToastMessageFlag from "../components/ToastFlag.vue";
// import AddComments from "../components/AddComment.vue";
import AddComment from "../components/AddComment.vue";
import moment from "moment";
import AppLoader from "./AppLoader.vue";

export default {
  name: "ChatBox",
  components: {
    ChatInput,
    ToastMessage,
    ToastMessageFlag,
    // AddComments,
    AppLoader,
    AddComment,
  },
  props: {
    chatId: {
      default: null,
      type: Number,
    },
  },
  data() {
    return {
      copiedToast: false,
      flagTost: false,
      moment: moment,
      time: "Last Wednesday at 2:08 PM ",
    };
  },
  methods: {
    copyTextControl(message) {
      navigator.clipboard.writeText(message);
      this.copiedToast = true;
      setTimeout(() => {
        this.copiedToast = false;
      }, 2000);
    },
    add_feedback(conv_id, chat_id) {
      localStorage.setItem("conversation_id", conv_id);
      localStorage.setItem("chat_id", chat_id);
    },
    scrollToEnd() {
      const container = this.$refs.scrollContainer;
      if (!container) {
        console.error("Scroll container reference is undefined");
        return;
      }
      setTimeout(() => {
        container.scrollTop = container.scrollHeight;
      }, 0);
    },
    flagTextControl() {
      if (this.flagTost == true) {
        this.flagTost = false;
      } else {
        this.flagTost = true;
      }
    },
    // getconversations() {
    //   const chatid = this.$route.params.id;
    //   this.$store
    //     .dispatch("conversation/get_user_conversations", chatid)
    //     .then((response) => {
    //       this.$store.commit("conversation/set_user_conversation", response.data.data.data);
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     });
    // },
  },
  mounted() {
    // this.getconversations();
    this.scrollToEnd();
  },
  computed: {
    conversations() {
      return this.$store.getters["conversation/conversations"];
    },
    load() {
      return this.$store.getters["conversation/loadingStatus"];
    },
  },
  watch: {
    conversations() {
      this.scrollToEnd();
    },
    chatId: {
      handler(new_val) {
        if (new_val !== null) {
          this.getconversations();
          this.scrollToEnd();
        }
      },
    },
  },
  destroyed() {
    this.$store.commit("conversation/set_user_conversation", []);
  },
};
</script>
