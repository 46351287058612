<template>
  <div id="signUpPage" class="bg-white">
    <div class="sign-up-wrapper">
      <div class="text-center">
        <img
          src="../assets/interceptionLogo.png"
          class="logo img-fluid"
          alt=" loading"
        />
        <h2 class="mt-4">Create Account</h2>
      </div>
      <div class="signUp-btn-wrapper">
        <b-form @submit.prevent="onSubmit">
          <b-form-group
            id="example-input-group-1"
            label="Name"
            label-for="example-input-1"
            class="mt-3"
          >
            <b-form-input
              id="example-input-1"
              UserName="example-input-1"
              class="input-fields"
              placeholder="User Name"
              type="text"
              v-model="$v.form.UserName.$model"
              :state="validateStateUserName('UserName')"
              aria-describedby="input-2-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-3.1-live-feedback"
              >This is a required field and must be at least 3
              characters.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group
            id="example-input-group-2"
            label="Email"
            label-for="example-input-2"
            class="mt-3"
          >
            <b-form-input
              id="example-input-2"
              UserEmail="example-input-2"
              placeholder="Email Address"
              class="input-fields"
              v-model="$v.form.UserEmail.$model"
              type="email"
              :state="validateStateUserEmail('UserEmail')"
              aria-describedby="input-6-live-feedback"
            ></b-form-input>

            <b-form-invalid-feedback id="input-6.1-live-feedback"
              >This is a required field and must be valid and follows email
              pattern example@gmail.com</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group
            id="example-input-group-3"
            label="Password"
            label-for="example-input-3"
            class="mt-3"
          >
            <div class="position-relative">
              <b-form-input
                id="example-input-3"
                Password="example-input-3"
                placeholder="Password"
                ref="password"
                v-model="$v.form.Password.$model"
                :state="validateStatePassword('Password')"
                aria-describedby="input-4-live-feedback"
                :type="showPassword ? 'text' : 'password'"
                class="input-fields password_fields"
              >
              </b-form-input>
              <span @click="toggleShowPassword" class="SignIn-btn-eye-close">
                <b-icon
                  class="text-danger"
                  v-if="showPassword"
                  icon="eye-fill"
                ></b-icon>
                <b-icon
                  class="text-black"
                  v-else
                  icon="eye-slash-fill"
                ></b-icon>
              </span>
            </div>
            <b-form-invalid-feedback id="input-4.1-live-feedback">
              Password must meet the following criteria: - Minimum length of 6
              and not be empty</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group
            id="example-input-group-4"
            label="Confirm Password"
            label-for="example-input-4"
            class="mt-3"
          >
            <div class="position-relative">
              <b-form-input
                id="example-input-4"
                confirmPassword="example-input-4"
                placeholder="Confirm Password"
                ref="confirmPass"
                class="input-fields password_fields"
                v-model="$v.form.confirmPassword.$model"
                :state="validateStateConfirmPassword('confirmPassword')"
                aria-describedby="input-5-live-feedback"
                :type="ConfirmPassword ? 'text' : 'password'"
              >
              </b-form-input>
              <span
                class="SignIn-btn-eye-close"
                ref="eyeBtn"
                @click="toggleShowConfirmPassword"
              >
                <b-icon
                  class="text-danger"
                  v-if="ConfirmPassword"
                  icon="eye-fill"
                ></b-icon>
                <b-icon
                  class="text-black"
                  v-else
                  icon="eye-slash-fill"
                ></b-icon>
              </span>
            </div>
            <b-form-invalid-feedback id="input-5.1-live-feedback">
              Password must be same invalid match</b-form-invalid-feedback
            >
          </b-form-group>
          <button type="submit" class="submition-button text-white">
            Sign Up
          </button>
        </b-form>
      </div>
      <div class="link-signIn text-center mt-4">
        <span>Already have an account?</span>
        <router-link to="/login"> Sign in </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
const emailPattern = (value) => {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return pattern.test(value);
};
export default {
  mixins: [validationMixin],
  name: "SignUp",
  data() {
    return {
      form: {
        Password: null,
        UserEmail: null,
        UserName: null,
        confirmPassword: null,
      },
      showPassword: false,
      ConfirmPassword: false,
    };
  },
  validations: {
    form: {
      UserEmail: {
        required,
        emailPattern,
      },
      UserName: {
        required,
        minLength: minLength(3),
      },
      Password: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        required,
        sameAsPassword: function (value) {
          return value === this.form.Password;
        },
      },
    },
  },
  methods: {
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const formData = {
        name: this.form.UserName,
        email: this.form.UserEmail,
        password: this.form.Password,
      };

      axios
        .post("api/register", formData)
        .then((response) => {
          this.$bvToast.toast("Successfully registered operation successful", {
            title: "Successfully registered",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "success",
          });
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("userId", response.data.data.id);
          this.$store.commit("user/SET_USER", response.data);
          if (this.$route.path !== "/") {
            setTimeout(() => {
              this.$router.push("/");
            }, 2000);
          }
          this.Reset();
        })
        .catch((error) => {
          this.$bvToast.toast(`Access denied : ${error}`, {
            title: "Operation unsuccessful",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "danger",
          });
          console.error(error);
        });
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    toggleShowConfirmPassword() {
      this.ConfirmPassword = !this.ConfirmPassword;
    },
    Reset() {
      this.form.UserEmail = "";
      this.form.UserName = "";
      this.form.Password = "";
      this.form.confirmPassword = "";
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    validateStateUserEmail(validateStateUserEmail) {
      const { $dirty, $error } = this.$v.form[validateStateUserEmail];
      return $dirty ? !$error : null;
    },
    validateStatePassword(validateStatePassword) {
      const { $dirty, $error } = this.$v.form[validateStatePassword];
      return $dirty ? !$error : null;
    },
    validateStateUserName(UserName) {
      const { $dirty, $error } = this.$v.form[UserName];
      return $dirty ? !$error : null;
    },
    validateStateConfirmPassword() {
      const validationState = this.$v.form.confirmPassword;
      if (!validationState.$dirty) return null;
      return !validationState.$error;
    },
    isValid() {
      alert("running");
      if (
        this.$refs.password.classList.contains("is-valid") ||
        this.$refs.password.classList.contains("is-invalid")
      ) {
        this.$refs.eyeBtn.style.right = "16px";
        return true;
      }
    },
  },
  watch: {
    "$refs.password.classList.contains('is-valid')": {
      handler: "isValid",
      deep: true,
    },
  },
};
</script>
