<template>
  <div class="CopyToastMsg d-flex bg-white" v-if="toastControl">
    <img src="../assets/copied.svg" class="copiedLogo" alt="loading" />
    <h6 class="m-0 ml-2 mr-2">Text copied to clipboard</h6>
    <span>
      <img
        src="../assets/close.svg"
        @click="toastController"
        class="btn-closed"
        alt="loading"
      />
    </span>
  </div>
</template>
<script>
export default {
  name: "ToastMessageComment",
  data() {
    return {
      toastControl: true,
    };
  },
  methods: {
    showToast() {
      this.toastControl = true;
    },
    toastController() {
      this.toastControl = false;
    },
  },
  mounted() {
    this.showToast();
  },
};
</script>
