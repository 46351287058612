<template>
  <div id="chatbox-input-area">
    <form @submit.prevent="query()">
      <div class="d-flex inputField d-flex">
        <b-form-textarea
          id="textarea"
          class="query-input-field"
          rows="3"
          v-model="askAi"
          max-rows="6"
          @keypress.enter="query()"
        ></b-form-textarea>
        <button class="submit-query b-0" type="submit">
          <img src="../assets/send.svg" alt="loading" />
        </button>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  name: "ChatInput",
  data() {
    return {
      askAi: "",
    };
  },
  methods: {
    query() {
      const chatData = {
        chat_id: this.$route.params.id,
        message: this.askAi,
        type: "user",
      };
      this.$store.commit("conversation/SET_CHAT_CONVERSATION", chatData);
      this.askAi = "";
      this.$store.commit("conversation/SET_LOADER", true);
      this.$store
        .dispatch("conversation/POST_MESSAGE_TO_AI", chatData)
        .then((aiResponse) => {
          this.$store.commit("conversation/SET_LOADER", false);
          this.$store.commit(
            "conversation/SET_CHAT_CONVERSATION",
            aiResponse.data.data
          );
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit("conversation/SET_LOADER", false);
        });
    },
  },
};
</script>
