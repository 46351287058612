import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/LoginPage.vue";
import HomeDashboard from "../views/HomeDashboard.vue";
import SignUp from "../views/SignUp.vue";
import ChatsPage from "../views/ChatsPage.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/", name: "homeDashboard", component: HomeDashboard },
  { path: "/login", name: "LoginPage", component: LoginPage },
  { path: "/signup", name: "SigninPage", component: SignUp },
  { path: "/chat/:id", name: "ChatsPage", component: ChatsPage },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");

  // If token is absent, redirect to login unless already on login/signup page
  if (!token) {
    if (to.path !== "/login" && to.path !== "/signup") {
      return next("/login");
    }
    return next();
  }
  // If token is present, redirect from login/signup to the home page
  if (to.path === "/login" || to.path === "/signup") {
    return next("/");
  }
  // If token is present and not on login/signup, continue to the requested page
  next();
});

export default router;
