<template>
  <div id="SideBar" v-show="show">
    <div class="wrapper-inner p-4">
      <div class="header pt-2">
        <img
          src="../assets/interceptionLogo.png"
          class="img-fluid logo"
          alt="loading"
        />
      </div>
      <img
        src="../assets/close.svg"
        alt="loading"
        @click="closeSideBar()"
        class="closeBtn border-0"
      />
      <div class="sidebars flex-column justify-content-between">
        <div class="innerWrapper">
          <ul class="mt-5 p-0 pb-3">
            <router-link to="/" class="router-link">
              <li class="mt-2">
                <router-link to="/">
                  <button class="buttons text-black d-flex rounded-md bg-white">
                    <img src="../assets/home.svg" alt="loading" />
                    <span> Home </span>
                  </button>
                </router-link>
              </li>
            </router-link>

            <li class="mt-2">
              <button
                class="buttons text-black d-flex rounded-md bg-white"
                @click="showSideBarThreads()"
              >
                <img src="../assets/chat.svg" alt="loading" />
                <span> Threads </span>
              </button>
            </li>
          </ul>
          <div class="threads-Wrapper" v-if="sidebarThreads">
            <ul>
              <li
                class="thread"
                v-for="(thread, index) in threads"
                :key="index"
              >
                <router-link :to="`/chat/${thread.id}`">
                  <div class="chat-icon">
                    <img src="../assets/chat.svg" class="mr-3" alt="loading" />
                  </div>
                  <div class="chat-title">
                    {{ thread.title }}
                  </div>
                  <div class="chat-count" v-if="thread.count">
                    {{ thread.count }}
                  </div>
                </router-link>
              </li>
            </ul>
            <infinite-loading @infinite="infiniteHandler"></infinite-loading>
          </div>
          <button
            class="btn-threads text-white pt-3 pb-3 rounded-md"
            @click="showChatsBox()"
          >
            <span> New threads </span>
          </button>
        </div>
        <div class="profileControllers">
          <button class="btn-settings bg-transparent border-0">
            <img src="../assets/settings.svg" alt="loading" />
            <span>Settings</span>
          </button>
          <div class="profileview d-flex">
            <div class="d-flex align-items-center side-bar-profile-wrapper">
              <div class="side-bar-profileImage">
                {{ getUser ? getUser.charAt(0) : "" }}
              </div>
              <div class="userName">{{ getUser }}</div>
            </div>
            <img
              src="../assets/logout.svg"
              class="btn-logout"
              @click="logout()"
              alt="loading"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
export default {
  name: "SideBar",
  props: { sideBarControl: Boolean },
  data() {
    return {
      show: true,
      sidebarThreads: false,
      page: 1,
    };
  },
  methods: {
    closeSideBar() {
      this.show = !this.show;
    },
    showSideBarThreads() {
      this.sidebarThreads = !this.sidebarThreads;
      this.$store.commit("threads/SHOW_THREADS_COMPONENT", this.sidebarThreads);
    },
    showChatsBox() {
      if (this.$route.path !== "/") {
        this.$router.push("/").catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            console.error("Navigation Error:", err);
          } else {
            console.warn("Navigation to the same route was prevented.");
          }
        });
      } else {
        this.closeSideBar();
      }
    },
    logout() {
      this.$store.commit("conversation/GLOBAL_LOADER", true);
      axios
        .get("api/user/logout")
        .then(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          setTimeout(() => {
            this.$router.push("/login");
            this.$store.commit("conversation/GLOBAL_LOADER", false);
          }, 2000);
        })
        .catch((error) => {
          this.$store.commit("conversation/GLOBAL_LOADER", false);
          console.error(error);
        });
    },
    infiniteHandler($state) {
      this.$store
        .dispatch("threads/GET_THREADS", this.page)
        .then((response) => {
          const threadsList = response.data.data.data;
          threadsList.forEach((element) => {
            this.$store.commit("threads/SET_THREADS", element);
          });
          if (this.page == response.data.data.last_page) {
            $state.complete();
          } else {
            $state.loaded();
            this.page += 1;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  computed: {
    threads() {
      return this.$store.getters["threads/threads"];
    },
    getUser() {
      return this.$store.getters["user/GET_USER"].name;
    },
  },
  destroyed() {
    this.$store.commit("threads/STATE_REFRESH");
  },
};
</script>
