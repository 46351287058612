import axios from "@/axios";
export default {
  namespaced: true,
  state: {
    messages: [],
    loading: false,
    globalLoader: false,
  },
  getters: {
    conversations(state) {
      return state.messages;
    },
    loadingStatus(state) {
      return state.loading;
    },
    GlobalLoader(state) {
      return state.globalLoader;
    },
  },
  mutations: {
    SET_CHAT_CONVERSATION(state, data) {
      state.messages.push(data);
    },
    set_user_conversation(state, data) {
      state.messages = data;
    },
    SET_LOADER(state, loadingstate) {
      state.loading = loadingstate;
    },
    GLOBAL_LOADER(state, globalload) {
      state.globalLoader = globalload;
    },
  },
  actions: {
    // eslint-disable-next-line no-empty-pattern
    GET_CONVERSATION({}, payload) {
      const formData = {
        title: payload.title,
      };
      return axios.post("api/user/chat", formData);
    },
    // eslint-disable-next-line no-empty-pattern
    POST_MESSAGE_TO_AI({}, payload) {
      return axios.post("api/user/conversation", payload);
    },
    // eslint-disable-next-line no-empty-pattern
    get_user_conversations({}, payload) {
      return axios.get(`api/user/conversation/${payload}`);
    },
  },
};
