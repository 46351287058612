import axios from "@/axios";
export default {
  namespaced: true,
  state: {
    user: {},
    loggedInUserID: null,
  },
  getters: {
    GET_USER(state) {
      return state.user;
    },
  },
  mutations: {
    SET_USER(state, data) {
      state.user = data;
    },
  },
  actions: {
    // eslint-disable-next-line no-empty-pattern
    GET_USER_AUTH({}) {
      return axios.get("api/user/get-auth-user");
    },
  },
};
