<template>
  <div>
    <button v-b-modal.add-comment-modal class="bg-light btn-comment">
      Add comment
    </button>
    <b-modal id="add-comment-modal" centered size="lg">
      <template #modal-header>
        <div class="modal-wrapper">
          <div class="headers">
            <h5>Add a comment</h5>
            <p>The Amass team will see your comment.</p>
          </div>
          <span>
            <img
              src="../assets/close.svg"
              @click="$bvModal.hide('add-comment-modal')"
              class="btn-closed"
              alt="loading"
              @keyup.enter="addFeedback()"
          /></span>
        </div>
      </template>
      <b-form-textarea
        id="textarea"
        rows="3"
        v-model="comment"
        max-rows="6"
      ></b-form-textarea>
      <template #modal-footer>
        <button
          @click="$bvModal.hide('add-comment-modal')"
          class="comment-modal-btn-cancle"
        >
          cancel
        </button>
        <button
          @click="addFeedback(), $bvModal.hide('add-comment-modal')"
          class="modal-btn-add-comment"
        >
          Add comment
        </button>
      </template>
    </b-modal>
    <ToastMessageComment v-if="comentToast" />
  </div>
</template>
<script>
import ToastMessageComment from "../components/CommentedToast.vue";
export default {
  name: "AddComment",
  components: {
    ToastMessageComment,
  },
  props: {
    messageId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      modelCloses: true,
      comment: "",
      loading: false,
      comentToast: false,
      modalComment: false,
    };
  },
  methods: {
    modelCloser() {
      this.modelCloses = !this.modelCloses;
    },
    addFeedback() {
      const data = {
        chat_id: this.$route.params.id,
        conversation_id: this.messageId,
        feedback: this.comment,
      };
      this.loading = true;
      this.$store
        .dispatch("feedback/Add_Feedback", data)
        .then(() => {
          this.loading = false;
          this.comment = "";
          this.comentToast = true;
        })
        .catch(() => {
          this.loading = false;
          this.comment = "";
          this.$bvToast.toast("Something went wrong", {
            title: "unsuccesful operation",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "light",
          });
        });
    },
  },
};
</script>
