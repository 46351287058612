import axios from "@/axios";
export default {
  namespaced: true,
  state: {
    threads: [],
    showThreads: false,
  },
  getters: {
    threads(state) {
      return state.threads;
    },
    show_Threads(state) {
      return state.showThreads;
    },
  },
  mutations: {
    SET_THREADS(state, data) {
      state.threads.push(data);
    },
    SHOW_THREADS_COMPONENT(state, data) {
      state.showThreads = data;
    },
    STATE_REFRESH(state) {
      state.threads = [];
    },
  },
  actions: {
    // eslint-disable-next-line no-empty-pattern
    GET_THREADS({}, page = 1) {
      return axios.get(`api/user/chat?page=${page}`);
    },
  },
};
