import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
// import moment from "moment";
import "bootstrap-vue/dist/bootstrap-vue.css";
import InfiniteLoading from "vue-infinite-loading";
// import InfiniteError from "path/to/your/components/InfiniteError";
Vue.use(InfiniteLoading);
// Vue.use(InfiniteError);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
// Vue.use(moment);
import "@/global.css";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
