<template>
  <div>
    <div class="toastMsg d-flex bg-white" v-if="toastControl">
      <img src="../assets/flag.svg" class="copiedLogo" alt="loading" />
      <div class="toast-wrapper">
        <h5>Answer flagged as inaccurate</h5>
        <p>
          We’d appreciate if you add a comment to let us know more about why
          Gema’s answer didn’t live up to your expectations
        </p>
        <h5 class="addComments" v-b-modal.add-comment-modal>Add comment</h5>
      </div>
      <img
        src="../assets/close.svg"
        @click="toastController"
        class="btn-closed mb-2"
        alt="loading"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "ToastMessage",
  data() {
    return {
      toastControl: true,
    };
  },
  methods: {
    showToast() {
      setTimeout(() => {
        this.toastControl = false;
      }, 2000);
    },
    toastController() {
      this.toastControl = false;
    },
  },
  mounted() {
    this.showToast();
  },
};
</script>
