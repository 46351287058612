<template>
  <div class="sidebar-header-inner-wrapper">
    <SideBar v-if="sideBarControl" />
    <div class="sideBarWrapper">
      <div class="d-flex justify-content-between">
        <img
          src="../assets/interceptionLogo.png"
          class="logo img-fluid"
          alt="loading"
        />
        <img
          src="../assets/hamburger.svg"
          class="btn-burger"
          alt="loading"
          @click="showSideBar()"
        />
      </div>
      <div class="side-bar-outer-wraper flex-column justify-content-between">
        <div class="innerWrapper">
          <ul class="p-0 pb-2">
            <router-link to="/" class="router-link">
              <li>
                <button class="side-bar-btns text-black d-flex rounded-md">
                  <img src="../assets/home.svg" alt="loading" />
                  <span> Home </span>
                </button>
              </li>
            </router-link>

            <li class="mt-1">
              <button
                class="side-bar-btns text-black d-flex active rounded-md"
                @click="showThreads()"
              >
                <img src="../assets/chat.svg" alt="loading" />
                <span> Threads </span>
              </button>
            </li>
          </ul>
          <button
            class="side-bar-btn-threads text-white mt-1 rounded-md"
            @click="showChatsBox()"
          >
            <span> New thread </span>
          </button>
        </div>
        <div class="side-bar-profile pt-3 d-flex">
          <div class="d-flex align-items-center side-bar-profile-wrapper">
            <div class="side-bar-profileImage">
              {{ getUser ? getUser.charAt(0) : "" }}
            </div>
            <div class="userName">{{ getUser }}</div>
          </div>
          <button class="bg-transparent border-0" @click="logout()">
            <img src="../assets/logout.svg" class="btn-logout" alt="loading" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios";
import SideBar from "../components/SideBar.vue";
export default {
  name: "HomeSideBar",
  components: {
    SideBar,
  },
  data() {
    return {
      sideBarControl: false,
      threads: false,
      askAi: "",
      token: localStorage.getItem("token"),
      alphaName: "",
    };
  },
  methods: {
    showSideBar() {
      this.sideBarControl = !this.sideBarControl;
    },
    showThreads() {
      this.threads = !this.threads;
      this.$store.commit("threads/SHOW_THREADS_COMPONENT", this.threads);
    },
    showChatsBox() {
      if (this.$route.path !== "/") {
        this.$router.push("/").catch((err) => {
          if (err.name !== "NavigationDuplicated") {
            console.error("Navigation Error:", err);
          } else {
            console.warn("Navigation to the same route was prevented.");
          }
        });
      } else {
        this.closeSideBar();
      }
    },
    logout() {
      this.$store.commit("conversation/GLOBAL_LOADER", true);
      axios
        .get("api/user/logout")
        .then(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          setTimeout(() => {
            this.$router.push("/login");
            this.$store.commit("conversation/GLOBAL_LOADER", false);
          }, 2000);
        })
        .catch((error) => {
          this.$store.commit("conversation/GLOBAL_LOADER", false);
          console.error(error);
        });
    },
    async get_user_auth() {
      await this.$store
        .dispatch("user/GET_USER_AUTH")
        .then((response) => {
          localStorage.setItem("userId", response.data.data.id);
          this.$store.commit("user/SET_USER", response.data.data);
        })
        .catch((err) => {
          if (err.response.data.message.includes("login again")) {
            this.$bvToast.toast(err.response.data.message, {
              title: "Error",
              autoHideDelay: 3000,
              appendToast: true,
              variant: "danger",
            });
            localStorage.removeItem("token");
            localStorage.removeItem("userId");
            setTimeout(() => {
              this.$router.push("/login");
            }, 3000);
          }
        });
    },
  },
  async mounted() {
    this.$store.commit("threads/SHOW_THREADS_COMPONENT", false);
    await this.get_user_auth();
  },
  computed: {
    getUser() {
      return this.$store.getters["user/GET_USER"].name;
    },
  },
};
</script>
