<template>
  <div class="text-center d-flex justify-content-center global-loader">
    <b-spinner variant="danger" label="Spinning"></b-spinner>
  </div>
</template>
<script>
export default {
  name: "GlobalLoader",
};
</script>
