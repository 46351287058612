<template>
  <div class="chat-spinner-loader">
    <span class="position-relative loader d-flex">
      <b-spinner type="grow" class="bg-dark" label="Loading..."></b-spinner>
      <b-spinner type="grow" class="bg-danger" label="Loading..."></b-spinner>
      <b-spinner
        type="grow"
        class="bg-secondary"
        label="Loading..."
      ></b-spinner>
    </span>
  </div>
</template>

<script>
export default {
  name: "AppLoader",
};
</script>
